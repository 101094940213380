import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Hero, SEO } from 'components';
import { Layout } from 'layouts';
import heroImg from '../../static/img/hero.svg';

const Index = ({ data }) => {
  return (
    <Layout>
      <SEO
        banner='https://media.graphcms.com/uFJ53LFSKCtLGAAakaYu'
        description='Modern Applications. Built Fast. Delivered Securely. Stackery is a serverless platform to design, develop and deliver modern applications.'
      />
      <Hero cover={heroImg} title={'Modern applications — built fast, delivered securely.'} />
    </Layout>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.shape({
      featureGroup: PropTypes.object.isRequired
    }).isRequired
  }).isRequired
};

export const postQuery = graphql`
  query {
    gcms {
      featureGroup(where: {title: "index"}) {
        id
        title
        mainFeature {
          id
          featureTitle
          featureText
          linkText
          linkURL
          featureIcon {
            url
          }
        }
        feature {
          id
          featureTitle
          featureText
          linkText
          linkURL
          featureIcon {
            url
          }
        }
      }
    }
  }
`;
